import React from "react";

const CreateRoom = () => {
    return (
        <div className="App">
            <div className="auth-form-container">
                <h2>BabyCode Calling Platform</h2>
            </div>
        </div>
    )
}

export default CreateRoom