import React from "react";

const EndCallPage = () => {
    return (
        <div className="App">
            <div className="auth-form-container">
                <h2>Your Call has Ended</h2>
            </div>
        </div>
    )
}

export default EndCallPage